export type HostId = string;

//#region Metric IDS
export const cpuMetric = "libvirt.guest_time_per_vcpu_delta";
export const memoryMetric = "libvirt.used_memory_kb";
export const blockWriteMetric = "libvirt.block_wr_bytes_delta";
export const netTxMetric = "libvirt.net_tx_bytes_delta";
export const netRxMetric = "libvirt.net_rx_bytes_delta";

const allMetrics = [cpuMetric, memoryMetric, blockWriteMetric, netTxMetric, netRxMetric] as const;

export type MetricId = (typeof allMetrics)[number];
//#endregion

//#region Metric sets
export interface MetricSet {
    name: string;
    metrics: readonly MetricId[];
}

export const cpuSet = { name: "cpu", metrics: [cpuMetric] } as const satisfies MetricSet;
export const otherSet = {
    name: "other",
    metrics: allMetrics.filter((m) => m !== cpuMetric),
} as const satisfies MetricSet;
//#endregion
