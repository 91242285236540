import invariant from "tiny-invariant";
import { getWebsocketUrl, type ApiParameters } from "../../api/createApiClient.ts";

interface WsUrlParams extends ApiParameters {
    location: string;
    query: string;
    subscribe: boolean;
}

export function getMetricsWsUrl({ apiPrefix, location, apikey, query, subscribe }: WsUrlParams) {
    invariant(apikey, "API key must be set to create a socket");
    const search = new URLSearchParams({ apikey, query, subscribe: subscribe ? "true" : "false" });
    return `${getWebsocketUrl(apiPrefix)}/${location}/metrics-ws/index?${search}`;
}
