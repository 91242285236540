import isChromatic from "chromatic";
import { atom } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { isMockApiEnabled } from "../../../mock-api/msw/mswInit.ts";
import { randomFloatBetween } from "../../../mock-api/randomTypes.ts";
import { currentApiParamsAtom } from "../../api/apiClient.store.ts";
import type { HostId, MetricId, MetricSet } from "./metricIds.ts";
import type { MetricsEvent } from "./metrics.schema.ts";
import { parseMetricsEvent } from "./metrics.schema.ts";
import { getMetricsWsUrl } from "./metricsUtils.ts";

//#region Riemann Query DSL
function rieEscape(s: string) {
    return JSON.stringify(s);
}

function eq(field: string, value: string): string {
    return `${field} = ${rieEscape(value)}`;
}

function _match(field: string, value: string): string {
    return `${field} =~ ${rieEscape(value)}`;
}

function any(op: (field: string, value: string) => string, field: string, values: readonly string[]): string {
    return or(...values.map((value) => op(field, value)));
}

function and(...clauses: readonly string[]) {
    return `(${clauses.join(" and ")})`;
}

function or(...clauses: readonly string[]) {
    return `(${clauses.join(" or ")})`;
}
//#endregion

const PARSE_ERROR_CLOSE_CODE = 4000;

export interface MetricsSocket {
    onopen?: () => void;
    onmessage?: (event: MetricsEvent) => void;
    onclose?: (wasClean: boolean) => void;

    close(): void;
}

class WsSocket implements MetricsSocket {
    onopen: MetricsSocket["onopen"] = undefined;
    onmessage: MetricsSocket["onmessage"] = undefined;
    onclose: MetricsSocket["onclose"] = undefined;

    constructor(private readonly socket: WebSocket) {
        socket.onopen = () => {
            this.onopen?.();
        };
        socket.onclose = (e) => {
            this.onclose?.(e.wasClean && e.code !== PARSE_ERROR_CLOSE_CODE);
        };
        socket.onmessage = ({ data }: { data: unknown }) => {
            try {
                const event = parseMetricsEvent(data as string);
                this.onmessage?.(event);
            } catch (e) {
                console.error("Failed to parse event: %o", data, e);
                // Close to prevent further error spam
                this.socket.close(PARSE_ERROR_CLOSE_CODE, "Failed to parse event");
            }
        };
    }

    close() {
        this.socket.close();
    }
}

export type SocketFactory = (location: string, metricSet: MetricSet, hosts: HostId[]) => MetricsSocket;

export const socketFactoryAtom = atom((get): SocketFactory => {
    if (isMockApiEnabled()) {
        return mockFactory;
    }

    const params = get(currentApiParamsAtom);
    return (location, metricSet, hosts) => {
        const query = and(any(eq, "host", hosts), any(eq, "service", metricSet.metrics));
        const url = getMetricsWsUrl({ location, query, subscribe: true, ...params });
        return new WsSocket(new WebSocket(url));
    };
});

const fakeValue = {
    "libvirt.guest_time_per_vcpu_delta": 1,
    "libvirt.used_memory_kb": 50_000_000,
    "libvirt.block_wr_bytes_delta": 1_500_000,
    "libvirt.net_tx_bytes_delta": 200_000,
    "libvirt.net_rx_bytes_delta": 100_000,
} satisfies Partial<Record<MetricId, number>>;

export const mockFactory: SocketFactory = (location, metricSet, hosts) => {
    const CONNECT_DELAY = isChromatic() ? 1 : 200;
    const METRIC_INTERVAL = 5000;

    return new (class implements MetricsSocket {
        onopen: MetricsSocket["onopen"] = undefined;
        onmessage: MetricsSocket["onmessage"] = undefined;
        onclose: MetricsSocket["onclose"] = undefined;

        mockEventInterval: ReturnType<typeof setInterval> | undefined;

        constructor() {
            console.debug("Mock metrics socket @%s [%s]", location, metricSet.metrics.join(", "));
            setTimeout(() => {
                this.onopen?.();

                // Send initial set of events
                this.sendMockEvents();

                if (!isChromatic()) {
                    // Send new events periodically
                    this.mockEventInterval = setInterval(() => this.sendMockEvents(), METRIC_INTERVAL);
                } else {
                    // Push fake events all at once for Chromatic
                    for (let i = 0; i < 5; i++) {
                        this.sendMockEvents(i);
                    }
                }
            }, CONNECT_DELAY);
        }

        sendMockEvents(fakeIdx = 0) {
            const deltaT = fakeIdx * METRIC_INTERVAL;

            for (const host of hosts) {
                for (const metric of metricSet.metrics) {
                    const baseValue = fakeValue[metric] ?? 1;
                    const value = isChromatic() ? baseValue / (2 + fakeIdx) : randomFloatBetween(0, baseValue);
                    const time = new Date(Date.now() + deltaT).toISOString();

                    this.onmessage?.({
                        host,
                        service: metric,
                        state: "running",
                        description: null,
                        // NB: Do not use random values in Chromatic to make screenshots consistent
                        metric: value,
                        tags: [],
                        time,
                        ttl: 120,
                        instance: host,
                        hv_name: "mock",
                        domain: "mock",
                    });
                }
            }
        }

        close() {
            invariant(this.mockEventInterval, "Socket must be open to close");
            clearInterval(this.mockEventInterval);
            setTimeout(() => {
                this.onclose?.(true);
            }, 1);
        }
    })();
};
