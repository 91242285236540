import { makeOnce } from "@warrenio/utils/makeOnce";
import { z } from "zod";

export const MetricsEvent = makeOnce(() =>
    z
        .object({
            time: z.string(),
            host: z.string(),
            service: z.string(),
            state: z.string(),

            description: z.unknown().nullable().optional(),
            metric: z.number().optional(),
            tags: z.array(z.string()).optional(),
            ttl: z.number().optional(),
            instance: z.string().optional(),
            hv_name: z.string().optional(),
            domain: z.string().optional(),
        })
        .passthrough(),
);

export interface MetricsEvent extends z.infer<ReturnType<typeof MetricsEvent>> {}

export function parseMetricsEvent(data: string): MetricsEvent {
    const obj = JSON.parse(data);
    return import.meta.env.DEV ? MetricsEvent().parse(obj) : (obj as MetricsEvent);
}

const _exampleEvent: MetricsEvent = {
    host: "482cf67b-0156-49d1-a99d-006b5aa8ca47",
    service: "libvirt.guest_time_per_vcpu_delta",
    state: "running",
    description: null,
    metric: 0.017774110200116412,
    tags: ["482cf67b-0156-49d1-a99d-006b5aa8ca47", "test-tll-hv-1", "482cf67b-0156-49d1-a99d-006b5aa8ca47", "running"],
    time: "2024-07-06T12:27:55.000Z",
    ttl: 120.0,
    instance: "482cf67b-0156-49d1-a99d-006b5aa8ca47",
    hv_name: "test-tll-hv-1",
    domain: "482cf67b-0156-49d1-a99d-006b5aa8ca47",
};

const _exampleExpiredEvent: MetricsEvent = {
    host: "66ca9f16-6e6a-4a5e-b075-01e047c5fea5",
    service: "libvirt.guest_time_per_vcpu_delta",
    state: "expired",
    time: "2024-09-13T11:32:07.962Z",
};
